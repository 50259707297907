import React from "react";
import * as styles from "../../styles/components/cta-double-card.module.scss"
import {IconArrowDown} from "../svg";
import StyledText, {TextStyles} from "../typography";
import ContentContainer from "../ContentContainer";
import Button from "../common/button/Button"
import useMediaQuery from "../../helpers/use-media-query";

type Props = {
  ctaCard: any;
}

const CtaDoubleCard = (props: Props) => {
  const {ctaCard} = props;
  const isMobileSize = useMediaQuery(`(max-width: 768px)`);
  return (
    <>
      {ctaCard ? <>
        {ctaCard.title &&
            <ContentContainer>
              <div className={styles.ctaTitle}>
                <StyledText variant={TextStyles.h2} tag="h2" className={styles.title}>
                  {ctaCard.title}
                </StyledText>
                <IconArrowDown/>
              </div>
            </ContentContainer>
        }

        <section className={styles.grid}>
          <div className={styles.left}>
            <StyledText variant={TextStyles.h3} className={styles.text}>
              {ctaCard.cardLeft.description}
            </StyledText>
            <Button variant={isMobileSize ? 'contained' : 'outlined'} href={ctaCard.cardLeft.url.link} trackInitiateEvent>
              <StyledText variant={TextStyles.button} tag="span">
                {isMobileSize ? ctaCard.cardLeft.mobileLabel : ctaCard.cardLeft.label}
              </StyledText>
            </Button>
          </div>
          <div className={styles.right}>
            <StyledText variant={TextStyles.h3} className={styles.text}>
              {ctaCard.cardRight.description}
            </StyledText>

            <Button variant='contained' color='secondary' href={ctaCard.cardRight.url.link} trackInitiateEvent>
              <StyledText variant={TextStyles.button} tag="span">
                {isMobileSize ? ctaCard.cardRight.mobileLabel : ctaCard.cardRight.label}
              </StyledText>
            </Button>
          </div>
        </section>
      </> : <></>
      }
    </>
  )
}

export default CtaDoubleCard
